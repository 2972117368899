import { z } from "zod";

export const addressSchema = z.object({
  id: z.string().uuid(),
  userId: z.string().uuid(),
  address: z.string(),
  chain: z.string(),
  isDefault: z.boolean(),
  isVerified: z.boolean(),
  isPayable: z.boolean(),
  nickname: z.string().nullable(),
  createdAt: z.coerce.date().default(new Date()),
  updatedAt: z.coerce.date().default(new Date()),
  deletedAt: z.coerce.date().nullable(),
});

export const walletSchema = z.array(addressSchema);

export const connectorMetaSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("Dynamic"), authToken: z.string() }),
  z.object({
    type: z.literal("Aptos"),
    publicKey: z.string(),
    signature: z.string(),
  }),
  z.object({
    type: z.literal("Near"),
    publicKey: z.string(),
    signature: z.string(),
    callbackUrl: z.string().optional(),
  }),
  z.object({
    type: z.literal("Sei"),
    subType: z.discriminatedUnion("type", [
      z.object({ type: z.literal("evm"), signature: z.string() }),
      z.object({
        type: z.literal("sei"),
        signature: z.string(),
        publicKey: z.object({
          type: z.string(),
          value: z.string(),
        }),
      }),
    ]),
  }),
  z.object({
    type: z.literal("Stellar"),
    signature: z.string(),
  }),
  z.object({
    type: z.literal("Sui"),
    bytes: z.string(),
    signature: z.string(),
  }),
]);

export const addressUpsertSchema = z.object({
  nickname: z.string().optional(),
  chain: z.string(),
  address: z.string(),
  isVerified: z.boolean().optional(),
  isDefault: z.boolean().optional(),
  isPayable: z.boolean().optional(),
  connectorMeta: connectorMetaSchema.optional(),
  walletUsed: z.string().optional(),
});

export const networkSchema = z.object({
  chain: z.string(),
  displayName: z.string(),
});

const web3ConnectorSchema = z.object({
  address: z.string(),
  chain: z.string(),
  connectorMeta: connectorMetaSchema,
  walletUsed: z.string().optional(),
});

export type Address = z.TypeOf<typeof addressSchema>;
export type AddressUpsert = z.TypeOf<typeof addressUpsertSchema>;
export type Web3Connector = z.TypeOf<typeof web3ConnectorSchema>;
export type Network = z.TypeOf<typeof networkSchema>;
export type Wallet = z.TypeOf<typeof walletSchema>;
